import * as Sentry from "@sentry/react";
import { isProd } from "./utils";

function truncateString(str: string, num: number) {
  // If the length of str is less than or equal to num
  // just return str--don't truncate it.
  if (str.length <= num) {
    return str;
  }
  // Return str truncated with '...' concatenated to the end of str.
  return str.slice(0, num) + "...";
}

export function captureException(e: any, context?: any) {
  if (!isProd()) {
    // throw the error
    if (e?.response) {
      // axios error
      console.error(e.response.data);
    } else {
      console.error(e);
    }
    return;
  }
  // capture the excpetion
  if (typeof context === "object") {
    Sentry.withScope((scope) => {
      const { user, ...tags } = context as any;
      if (context.user) {
        scope.setUser(user);
        scope.setTag("organizationId", user.organizationId);
      }
      // add the tags
      Object.keys(tags).forEach((key: any) => {
        const val = tags[key];
        const stringifiedVal = JSON.stringify(val);
        if (stringifiedVal && stringifiedVal.length > 200) {
          // truncate it on the tag and add it to context
          scope.setTag(key, truncateString(stringifiedVal, 199));
          scope.setExtra(key, stringifiedVal);
        } else {
          scope.setTag(key, truncateString(stringifiedVal, 199));
        }
      });
      Sentry.captureException(e);
    });
  } else {
    Sentry.captureException(e);
  }
}
